<template>
  <v-app>
    <v-row v-if="loading">
      <v-col cols="12" >
        <v-skeleton-loader
            v-bind="attrs"
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <div class="d-flex flex-row" v-else>
    <div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
         id="kt_profile_aside">
      <div class="card card-custom card-stretch" style="border-right: 1px solid #efefef;">
        <div class="card-body pt-4">
          <side-profile :user-detail="supervisorDetail"></side-profile>

          <!--Tab-->
          <tab-list :user-detail="supervisorDetail" @updateTabIndex="updateTabIndex"></tab-list>
        </div>
      </div>
    </div>
    <div class="flex-row-fluid ml-lg-8">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <b-tab active>
          <SupervisorProfile
              :supervisor_profile ="supervisorDetail"
              @update-supervisor="getSupervisorDetail"
          ></SupervisorProfile>
        </b-tab>

        <b-tab>
          <notes></notes>
        </b-tab>

        <b-tab>
          <PracticalCandidate></PracticalCandidate>
        </b-tab>

        <b-tab>
          <ExamCandidate></ExamCandidate>
        </b-tab>

        <b-tab>
          <CourseCandidate></CourseCandidate>
        </b-tab>

        <b-tab>
          <AmebAwardCandidate></AmebAwardCandidate>
        </b-tab>

        <b-tab>
          <transaction-summary></transaction-summary>
        </b-tab>

        <b-tab>
          <support-ticket></support-ticket>
        </b-tab>

        <b-tab>
          <email-activity></email-activity>
        </b-tab>

        <b-tab >
          <addresses :user_type="'accountholder'"></addresses>
        </b-tab>

        <b-tab>
          <user-role :user_role="supervisorDetail" @update="getSupervisorDetail()"></user-role>
        </b-tab>

        <b-tab>
          <SupervisorSettings  :user_score="supervisorDetail"  :supervisor_setting="supervisor_setting" @update-supervisor="getSupervisorDetail"></SupervisorSettings>
        </b-tab>

        <b-tab>
          <security :user_security="supervisorDetail"  @update="getSupervisorDetail()"></security>
        </b-tab>

        <b-tab>
          <information-log></information-log>
        </b-tab>

        <b-tab>
          <SupervisorChildrenCheck></SupervisorChildrenCheck>
        </b-tab>
      </b-tabs>
    </div>
  </div>
  </v-app>
</template>

<script>
import SupervisorProfile from "./widgets/Profile";
import SupervisorSettings from "./widgets/Settings";
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import UserRole from "@/view/pages/view/user/general/role/Role";
import Security from "@/view/pages/view/user/general/security/Security";
import Notes from "@/view/pages/view/user/general/notes/Notes";
import EmailActivity from "@/view/pages/view/user/general/email-activity/EmailActivity";
import InformationLog from "@/view/pages/view/user/general/information-log/InformationLog";
import SupervisorChildrenCheck from "@/view/pages/view/user/supervisor/widgets/SupervisorChildrenCheck";
import Addresses from "@/view/pages/view/user/general/address/Address";
import ExamCandidate from "@/view/pages/view/user/general/enrolments/ExamCandidate";
import CourseCandidate from "@/view/pages/view/user/general/enrolments/CourseCandidate";
import PracticalCandidate from "@/view/pages/view/user/general/enrolments/PracticalCandidate";
import SideProfile from "@/view/pages/view/user/general/side-profile/SideProfile";
import TabList from "@/view/pages/view/user/general/tab-list/TabList";
import SupportTicket from "@/view/pages/view/user/accountholder/widgets/SupportTicket";
import TransactionSummary from "@/view/pages/view/user/general/transaction/TransactionSummary";
import AmebAwardCandidate from "@/view/pages/view/user/general/enrolments/AmebAwardCandidate";

const supervisor=new SupervisorService();
export default {
  name: "supervisor",
  components: {
    TransactionSummary,
    SupportTicket,
    TabList,
    SideProfile,
    Addresses,
    SupervisorChildrenCheck,
    InformationLog,
    EmailActivity,
    Notes,
    SupervisorProfile,
    SupervisorSettings,
    UserRole,
    Security,
    ExamCandidate,
    CourseCandidate,
    PracticalCandidate,
    AmebAwardCandidate
  },
  data() {
    return {
      loading: true,
      attrs:[],
      tabIndex: 0,
      supervisorDetail:{},
      supervisor_setting:{},
    };
  },
  mounted() {
    this.getSupervisorDetail();

  },
  methods: {
    updateTabIndex(tabIndex) {
      this.tabIndex = tabIndex;
    },

    getSupervisorDetail() {
      supervisor
          .show(this.supervisorId)
          .then(response => {
            this.supervisorDetail=response.data.supervisor;
            this.supervisor_setting=this.supervisorDetail.supervisor_setting;
          }).finally(() => {
        this.loading = false
      })
    }
  },
  computed:{
    supervisorId() {
      return this.$route.params.supervisorId;
    },
    appEnv() {
      return process.env.VUE_APP_ENV;
    },
    routeTab(){
      if(this.supervisorDetail){
        let title = this.supervisorDetail.full_name + '- Supervisor'
        return {
          title: title
        }
      }
    }
  }

};
</script>
