<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">
            Supervisor information
          </h3>
        </div>
        <div class="card-toolbar" v-if="edit">
          <v-btn class="mx-2" x-large text  @click="close()">
            Cancel
          </v-btn>
          <v-btn :disabled="buttonDisabled" x-large dark  @click="updateProfile()">
            Save
          </v-btn>
        </div>
        <div class="card-toolbar" v-if="!edit">
          <v-btn
              text
              x-large
              @click="loginAsSupervisor(supervisor_profile.id)"
              class="ml-2 mr-1 cancel-btn"
          >
            Log in as Supervisor
          </v-btn>

          <v-btn x-large dark  @click="editProfile()">
            Edit
          </v-btn>
        </div>
      </div>

      <form class="form">
        <div class="card-body" v-if="edit">
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Title  <span class="text-danger"></span>:</label>
            <div class="col-lg-8 col-xl-6">
              <p class="is-invalid text-danger text-sm-body-1" v-if="errors.title">* {{errors.title[0]}}</p>
              <v-select
                  label="Title" :error="$v.supervisor_profile.title.$error"
                  v-model="supervisor_profile.title"
                  :menu-props="{ top: true, offsetY: true }"
                  :items="titles"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
              >
              </v-select>
              <span class="text-danger" v-if="$v.supervisor_profile.title.$error">This information is required</span>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">First name  <span class="text-danger">*</span></label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field outlined dense :error="$v.supervisor_profile.first_name.$error"
                            v-model="supervisor_profile.first_name"
              ></v-text-field>
              <span class="text-danger" v-if="$v.supervisor_profile.first_name.$error">This information is required</span>
              <span class="text-danger" v-if="errors.first_name">* {{errors.first_name[0]}}<br></span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Middle name</label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field outlined dense
                            v-model="supervisor_profile.middle_name"
              ></v-text-field>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Surname  <span class="text-danger">*</span></label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field
                  outlined :error="$v.supervisor_profile.last_name.$error"
                  dense
                  v-model="supervisor_profile.last_name"
              ></v-text-field>
              <span class="text-danger" v-if="errors.last_name">* {{errors.last_name[0]}}<br></span>
              <span class="text-danger" v-if="$v.supervisor_profile.last_name.$error">This information is required</span>
            </div>
          </div>

          <div class="form-group row align-items-center">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Enroller type <span class="text-danger">*</span></label>
            <div class="col-lg-9 col-xl-6">

              <v-container class="px-2 mx-2" fluid row>
                <v-checkbox  value="teacher" class="mr-3"
                             v-model="roleSelected"
                             label="Teacher">
                </v-checkbox>
                <v-checkbox   value="parent" class="mr-3"
                              v-model="roleSelected"
                              label="Parent">
                </v-checkbox>
                <v-checkbox  value="adult_learner" class="mr-3"
                             v-model="roleSelected"
                             label="Adult learner">
                </v-checkbox>
              </v-container>
              <span class="text-danger" v-if="errors.role">{{errors.role[0]}}<br></span>
              <span class="text-danger" v-if="$v.roleSelected.$error">This information is required</span>

            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email address  <span class="text-danger">*</span></label
            >
            <div class="col-lg-8 col-xl-6">
              <v-text-field @blur="checkDuplicateEmail" outlined dense :error="$v.supervisor_profile.email.$error"
                            v-model="supervisor_profile.email"
              ></v-text-field>
              <span class="text-danger" v-if="$v.supervisor_profile.email.$error">This information is required</span>
              <span class="text-danger" v-if="!$v.supervisor_profile.email.email">This information is not valid</span>
              <span class="font-weight-medium text-danger" v-if="messages">{{messages}}</span>

              <span class="text-danger" v-if="errors.email" >{{errors.email[0]}}</span>

            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"> Phone </label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field
                  outlined
                  dense
                  v-model="supervisor_profile.phone"
              ></v-text-field>
<!--              <span class="text-danger" v-if="errors.phone">* {{errors.phone[0]}}<br></span>-->
<!--              <span class="text-danger" v-if="$v.supervisor_profile.phone.$error">This information is required</span>-->
<!--              <span class="text-danger" v-if="!$v.supervisor_profile.phone.containTenDigit || !$v.supervisor_profile.phone.numeric">Phone must contain 10 digits or must be number</span>-->
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Mobile</label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field
                  outlined
                  dense
                  v-model="supervisor_profile.mobile"
              ></v-text-field>
<!--              <span class="text-danger" v-if="!$v.supervisor_profile.mobile.containTenDigit || !$v.supervisor_profile.mobile.numeric">Mobile must contain 10 digits or must be number</span>-->
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Work phone</label>
            <div class="col-lg-8 col-xl-6">
              <v-text-field
                  outlined
                  dense
                  v-model="supervisor_profile.work_phone"
              ></v-text-field>
<!--              <span class="text-danger" v-if="!$v.supervisor_profile.work_phone.containTenDigit || !$v.supervisor_profile.work_phone.numeric">Work Phone must contain 10 digits or must be number</span>-->
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Share email</label>
            <div class="col-lg-8 col-xl-6">
              <v-switch
                  outlined
                  dense
                  v-model="supervisor_profile.share_email"
              ></v-switch>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Share phone number</label>
            <div class="col-lg-8 col-xl-6">
              <v-switch
                  outlined
                  dense
                  v-model="supervisor_profile.share_phone_number"
              ></v-switch>
            </div>
          </div>
        </div>
        <div class="card-body" v-if="!edit">
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Full name</label>
            <div class="col-lg-8 col-xl-6" >
              <span class="font-size-h6 text-dark-75 text-hover-primary" v-copy="supervisor_profile.full_name">{{supervisor_profile.full_name ? supervisor_profile.full_name : 'NA' }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Enroller type</label>
            <div class="col-lg-8 col-xl-6">
              <span class="font-size-h6 text-dark-75 text-hover-primary" v-copy="supervisor_profile.phone">{{supervisor_profile.role_text ? supervisor_profile.role_text : 'NA' }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Email address</label>
            <div class="col-lg-8 col-xl-6">
              <span :href="'mailto:'+supervisor_profile.email" v-copy="supervisor_profile.email" class="font-size-h6 text-dark-75 text-hover-primary">{{supervisor_profile.email}}</span>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Phone</label>
            <div class="col-lg-8 col-xl-6">
              <span :href="'tel:'+supervisor_profile.phone" v-copy="supervisor_profile.phone" class="font-size-h6 text-dark-75 text-hover-primary">{{supervisor_profile.phone_format ? supervisor_profile.phone_format : 'NA' }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Mobile phone</label>
            <div class="col-lg-9 col-xl-6">
              <span :href="'tel:'+supervisor_profile.mobile" v-copy="supervisor_profile.mobile" class="font-size-h6 text-dark-75 text-hover-primary">{{supervisor_profile.mobile_format}}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Work phone</label>
            <div class="col-lg-9 col-xl-6">
              <span :href="'tel:'+supervisor_profile.work_phone ? supervisor_profile.work_phone_format : ''" v-copy="supervisor_profile.work_phone" class="font-size-h6 text-dark-75 text-hover-primary">{{supervisor_profile.work_phone_format ? supervisor_profile.work_phone_format : 'NA' }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Share email with enroller and examiner</label>
            <div class="col-lg-9 col-xl-6">
              <span class="badge font-size-h6 text-white text-hover-primary" v-bind:class="{'badge-primary': supervisor_profile.share_email , 'badge-danger': !supervisor_profile.share_email }">{{supervisor_profile.share_email ? 'Yes' : 'No' }}</span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right">Share phone with enroller and examiner</label>
            <div class="col-lg-9 col-xl-6">
              <span class="badge font-size-h6 text-white text-hover-primary" v-bind:class="{'badge-primary': supervisor_profile.share_phone_number , 'badge-danger': !supervisor_profile.share_phone_number }">{{supervisor_profile.share_phone_number ? 'Yes' : 'No' }}</span>
            </div>
          </div>
        </div>
      </form>

    </div>
  </v-app>
</template>
<script>
import {email, numeric, required} from "vuelidate/lib/validators";
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
const accountholder=new AccountholderService();
const supervisor=new SupervisorService();


export default {
  validations:{
    roleSelected:{required},
    supervisor_profile:{
      title:{},
      first_name:{required},
      last_name: {required},
      role: {},
      email: {required,email},
      // phone: {
      //   numeric,
      //   required,
      //   containTenDigit: function (value) {
      //     if(value) {
      //       return value.length === 10;
      //     }
      //     return true;
      //   }
      // },
      // mobile: {
      //   numeric,
      //   containTenDigit: function (value) {
      //     if(value) {
      //       return value.length === 10;
      //     }
      //     return true;
      //   }
      // },
      // work_phone: {
      //   numeric,
      //   containTenDigit: function (value) {
      //     if(value) {
      //       return value.length === 10;
      //     }
      //     return true;
      //   }
      // },
    }
  },
  props: ['supervisor_profile'],
  name: "SupervisorProfile",
  data() {
    return{
      edit: false,
      errors:[],
      roleSelected:[],
      titles:[
        {name: 'None', value: ''},
        {name: 'Ms', value: 'Ms'},
        {name: 'Miss', value: 'Miss'},
        {name: 'Mrs', value: 'Mrs'},
        {name: 'Mr', value: 'Mr'},
        {name: 'Mx', value: 'Mx'},
        {name: 'Dr', value: 'Dr'},
        {name: 'Prof', value: 'Prof'},
      ],
      duplciateEmailStatus:'',
      messages:'',
      buttonDisabled:false,
    }
  },
  methods:{
    loginAsSupervisor(supervisorId)
    {
      supervisor.loginAsSupervisor(supervisorId).then(response=>{
        if(response.data.status=='OK')
          window.open(response.data.url,'_blank')
        else
          this.$snotify.error("Oops something went wrong");
      }).catch(error=>{

      });
    },
    editProfile(){
      this.edit=true;
    },
    close(){
      this.edit=false;
    },
    submit(){
      this.edit=false;
    },
    updateProfile() {
      this.supervisor_profile['form_type'] = "update-accountholder-profile";
    this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if(this.roleSelected.length > 0){
          this.supervisor_profile.role=this.roleSelected.toString();
        }
        supervisor
            .update(this.supervisor_profile.id, this.supervisor_profile)
            .then(response => {
              this.$emit("update-supervisor");
              this.close();
              this.$snotify.success("Profile information updated");
            })
            .catch((err) => {
              this.errors = err.errors;
            });
      }
    },
    checkDuplicateEmail(){
      accountholder
          .checkDuplicateEmailForUpdate(this.supervisorId,this.supervisor_profile)
          .then(response => {
            this.messages=response.data.message;
            this.duplciateEmailStatus=response.data.status;
            if(this.duplciateEmailStatus=="SUCCESS"){
              this.buttonDisabled=false;
            }else{
              this.buttonDisabled=true;
            }
          })
          .catch(error => {
            this.errors = error.errors;
          })
    },
  },
  computed:{
    supervisorId() {
      return this.$route.params.supervisorId;
    },

  },
  mounted() {
    if(this.supervisor_profile.role){
      this.roleSelected=this.supervisor_profile.role.split(',');
    }

  }

}
</script>