<template>
  <div class="card card-custom">
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Supervisor Settings
        </h3>
      </div>
      <div class="card-toolbar" v-if="edit">
        <v-btn x-large @click="cancelEdit" class="cancel-btn mr-1" text>Cancel </v-btn>
        <v-btn  dark x-large
                @click="createOrUpdateSetting()"
        >
          Save
        </v-btn>

      </div>

      <div class="card-toolbar" v-else>
        <v-btn  dark x-large
                @click="editSetting()"
        >
          Edit
        </v-btn>

      </div>
    </div>

    <div class="card-body" v-if="edit">
      <div class="form-group row align-items-center">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">SCORE</label>
        <div class="ml-4 mr-4">
          <span>{{ user_score.score_name }}</span>
        </div>
        <div v-if="currentUser.access_type!='score'">
          <v-btn @click="changeScore" class="cancel-btn mr-1" text x-large>Change SCORE</v-btn>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Examining SCORE</label>
        <div class="col-lg-8 col-xl-6">
          <div class="row">
            <div class="col-auto mt-4">
              <div v-if="examining_scores.length > 0">
                <span class="mr-1" v-for="(item, index) in examining_scores" :key="index">
                  {{ item.name }} <span v-if="index != examining_scores.length - 1 ">,</span>
                </span>
              </div>

              <div class="ml-1" v-else>
                <span class="font-size-lg">
                  N/A
                </span>
              </div>
            </div>
            <div class="col-auto" v-if="currentUser.access_type!='score'">
              <v-btn @click="changeExaminingScore" class="cancel-btn mr-1" text x-large>Change examining SCORE</v-btn>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="form-group row align-items-center">-->
<!--        <label class="col-xl-4 col-lg-4 col-form-label text-right">Enews</label>-->
<!--        <div class="col-lg-8 col-xl-6">-->
<!--          <div class="checkbox-inline">-->
<!--            <v-btn x-large @click="changeNewsletterSubscription(subscription.is_newsletter_subscribed)" class="cancel-btn mr-1" text>{{subscription.is_newsletter_subscribed ? 'Unsubscribe' : 'Subscribe'}} </v-btn>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group row align-items-center">-->
<!--        <label class="col-xl-4 col-lg-4 col-form-label text-right">Score enews</label>-->
<!--        <div class="col-lg-8 col-xl-6">-->
<!--          <div class="checkbox-inline">-->
<!--            <v-btn x-large @click="changeScoreNewsletterSubscription(subscription.is_score_newsletter_subscribed)" class="cancel-btn mr-1" text>  {{subscription.is_score_newsletter_subscribed ? 'Unsubscribe' : 'Subscribe'}} </v-btn>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="form-group row align-items-center">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Notification Preference</label>
        <div class="col-lg-9 col-xl-6">
          <div class="radio-inline">
            <label class="radio mr-2 ml-1">
              <input type="radio" v-model="setting.notification_preference" value="email"/>
              <span></span>&nbsp;&nbsp;Email</label>
            <label class="radio mr-2 ml-1">
              <input v-model="setting.notification_preference" value="postal" type="radio"/>
              <span></span>&nbsp;&nbsp;Postal</label>
          </div>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Phone Preference</label>
        <div class="col-lg-9 col-xl-6">
          <div class="radio-inline">
            <label class="radio mr-2 ml-1">
              <input type="radio" v-model="setting.phone_preference" value="mobile"/>
              <span></span>&nbsp;&nbsp;Mobile</label>
            <label class="radio mr-2 ml-1">
              <input v-model="setting.phone_preference" value="home" type="radio"/>
              <span></span>&nbsp;&nbsp;Home</label>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Child safety active</label>
        <div class="col-lg-9 col-xl-6">
          <v-switch v-model="setting.has_wwcc" @change="toggleWwcc()" hide-details></v-switch>
        </div>
      </div>
      <div class="form-group row align-items-center" v-if="setting.has_wwcc">
        <label class="col-xl-4 col-lg-4 col-form-label text-right"></label>
        <div class="col-lg-4 col-xl-6">
          <div class="checkbox-inline">
            <v-text-field
                outlined label="Card number"
                dense
                v-model="user_setting.wwcc_number"
            ></v-text-field>
            <span class="text-danger" v-if="$v.user_setting.wwcc_number.$error">This information is required</span>
          </div>
        </div>
      </div>

      <div class="form-group row align-items-center" v-if="setting.has_wwcc">
        <label class="col-xl-4 col-lg-4 col-form-label text-right"></label>
        <div class="col-lg-4 col-xl-6">
          <div class="checkbox-inline">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="user_setting.wwcc_expiry_date"
                    prepend-icon=""
                    prepend-inner-icon="mdi-calendar"
                    @input="user_setting.wwcc_expiry_date = $event !== null ? $event : ''"
                    :error="$v.user_setting.wwcc_expiry_date.$error"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    label="Expiry date"
                    clearable
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="user_setting.wwcc_expiry_date"
                  no-title
                  @input="menu = false"
              ></v-date-picker>
              <span class="text-danger" v-if="$v.user_setting.wwcc_expiry_date.$error">This information is required</span>
            </v-menu>
            <!-- <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="user_setting.wwcc_expiry_date"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    label="Expiry date"
                    :error="$v.user_setting.wwcc_expiry_date.$error"
                    v-model="user_setting.wwcc_expiry_date"
                    prepend-icon=""
                    prepend-inner-icon="mdi-calendar"
                    readonly outlined dense
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="user_setting.wwcc_expiry_date"
                  no-title
                  scrollable
                  @input="menu = false"
              >
              </v-date-picker>
            </v-menu> -->
          </div>
          <span class="text-danger" v-if="$v.user_setting.wwcc_expiry_date.$error">This information is required</span>
        </div>
      </div>

      <div class="form-group row align-items-center" v-if="setting.has_wwcc">
        <label class="col-xl-4 col-lg-4 col-form-label text-right"></label>
        <div v-bind:class="(wwcc_image) ? 'col-lg-6' : 'col-lg-6' ">
          <v-file-input @change="previewWWCCImage" v-model="user_setting.wwcc_image" label="Upload"
                        dense outlined  prepend-inner-icon="mdi-file"
                        prepend-icon=""
          ></v-file-input>
        </div>
      </div>

      <div class="form-group row align-items-center" v-if="setting.has_wwcc && wwcc_image">
        <label class="col-xl-4 col-lg-4 col-form-label text-right"></label>
        <div class="col-lg-2 col-xl-2">
          <p><a :href="wwcc_image" target="_blank" >View</a> |
          <a href="#"  @click="deleteWWCCImg"  > Remove </a> </p>
<!--          <div class="checkbox-inline">-->
<!--            <v-img-->
<!--                class="img-thumbnail"-->
<!--                :lazy-src="wwcc_image"-->
<!--                :src="wwcc_image"-->
<!--                height="100"-->
<!--                width="100"-->
<!--            >-->
<!--              <v-btn v-if="wwcc_image"-->

<!--                     @click="deleteWWCCImg"-->
<!--                     x-small-->
<!--                     right-->
<!--                     fab-->
<!--                     absolute-->
<!--              >-->
<!--                <v-icon color="red">mdi-delete</v-icon>-->
<!--              </v-btn>-->
<!--            </v-img>-->
<!--          </div>-->
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Digital signature</label>
        <div class="col-xl-6" v-bind:class="(digital_signature_img) ? 'col-lg-7' : 'col-lg-9' ">
          <div class="checkbox-inline">
            <v-file-input @change="previewDigitalSignature" v-model="user_setting.digital_signature" label="Digital signature"
                          dense outlined  prepend-inner-icon="mdi-file"
                          prepend-icon=""
            ></v-file-input>
          </div>
        </div>
      </div>

      <div class="form-group row align-items-center" v-if="digital_signature_img">
        <label class="col-xl-4 col-lg-4 col-form-label text-right"></label>
        <div class="col-lg-8 col-xl-6" >
          <div class="checkbox-inline d-flex">
            <!-- <v-img
                :lazy-src="digital_signature_img"
                max-height="120"
                max-width="120"
                class="img-thumbnail"
                :src="digital_signature_img"
            ></v-img> -->
            <div>
              <v-btn v-if="digital_signature_img"
                @click="viewDigitalSignature(digital_signature_img)"
                class="btn btn-primary text-white"
                small
              >
                <i class="fa fa-eye"></i> View
              </v-btn>
            </div>
            <div>
              <v-btn
                @click="deleteDigitalSignatureImg"
                class="btn btn-danger ml-2"
                color="red"
                small
              >
                <i class="fa fa-trash"></i>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row align-items-center">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Grade preferences</label>
        <div class="col-lg-9 col-xl-6">
          <v-select  outlined label="Grade preferences" dense :items="grade_preferences"
                     v-model="user_setting.grade_preferences">
          </v-select>
        </div>
      </div>
      <div class="form-group row align-items-center">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Instrument preferences</label>
        <div class="col-lg-9 col-xl-6">
          <v-select  outlined label="Instrument preferences" dense :items="instrument_preferences"
                     v-model="user_setting.instrument_preferences">
          </v-select>
        </div>
      </div>

      <v-row>
        <v-col cols="12" md="6" v-if="this.user_setting.is_trainee_examiner">
          <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="user_setting.trainee_commencement_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="user_setting.trainee_commencement_date"
                  label="Trainee Commencement Date"
                  prepend-icon="mdi-calendar"
                  readonly outlined dense
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="user_setting.trainee_commencement_date"
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu1 = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu1.save(user_setting.trainee_commencement_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6" v-if="this.user_setting.is_trainee_examiner">
          <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="user_setting.trainee_completed_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="user_setting.trainee_completed_date"
                  label="Trainee Completed Date"
                  prepend-icon="mdi-calendar"
                  readonly outlined dense
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="user_setting.trainee_completed_date"
                no-title
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="menu2 = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="$refs.menu2.save(user_setting.trainee_completed_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <div class="form-group row">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Qualification</label>
        <div class="col-lg-9 col-xl-6">
          <v-textarea
              dense outlined row="3"
              v-model="user_setting.qualifications"
              label="Qualification"
          ></v-textarea>
        </div>
      </div>

    </div>

    <div class="card-body align-items-center" v-if="!edit">
      <div class="form-group row">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">SCORE:</label>
        <div class="col-lg-8 col-xl-6">
          {{ user_score.score_name }}
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-4col-form-label text-right">Examining SCORE:</label>
        <div class="col-lg-8 col-xl-8">
          <div class="row">
            <div class="col-auto">
              <div v-if="examining_scores.length > 0">
                <span class="mr-1" v-for="(item, index) in examining_scores" :key="index">
                  {{ item.name }} <span v-if="index != examining_scores.length - 1 ">,</span>
                </span>
              </div>

              <div class="ml-1" v-else>
                <span class="font-size-lg">
                  N/A
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Notification preference:</label>
        <div class="col-lg-8 col-xl-6">
          {{ user_setting.notification_preference_text }}
        </div>
      </div>

<!--      <div class="form-group row">-->
<!--        <label class="col-xl-4 col-lg-4 col-form-label text-right">Enews:</label>-->
<!--        <div class="col-lg-8 col-xl-6">-->
<!--          {{ user_setting.is_newsletter_subscribed?'Subscribed':'Not subscribed' }}-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="form-group row">-->
<!--        <label class="col-xl-4 col-lg-3 col-form-label text-right">Score enews:</label>-->
<!--        <div class="col-lg-8 col-xl-6">-->
<!--          {{ user_setting.is_score_newsletter_subscribed?'Subscribed':'Not subscribed' }}-->
<!--        </div>-->
<!--      </div>-->
      <div class="form-group row">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Phone preference:</label>
        <div class="col-lg-8 col-xl-6">
          {{ user_setting.phone_preference_text ? user_setting.phone_preference_text : 'N/A'}}
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Child safety active:</label>
        <div class="col-lg-8 col-xl-6">
          <span class="badge font-size-h6 text-white" v-bind:class="{'badge-primary': user_setting.has_wwcc , 'badge-danger': !user_setting.has_wwcc }">{{user_setting.has_wwcc ? 'Yes' : 'No' }}</span>
        </div>
      </div>

      <div v-if="user_setting.has_wwcc">
        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Card number:</label>
          <div class="col-lg-8 col-xl-6">
            {{user_setting.wwcc_number}}
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-4 col-lg-4 col-form-label text-right">Expiry date:</label>
          <div class="col-lg-8 col-xl-6">
            {{user_setting.formatted_wwcc_expiry_date? user_setting.formatted_wwcc_expiry_date:''}}
          </div>
        </div>
      </div>

      <div class="form-group row"  v-if="user_setting.wwcc_image_path">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Upload:</label>
        <div class="col-lg-8 col-xl-6">
          <a :href="user_setting.wwcc_image_path" v-if="user_setting.wwcc_image_path" target="_blank">View attachment</a>
        </div>
      </div>

      <div class="form-group row" v-if="user_setting.digital_signature_path">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Digital signature:</label>
        <div class="col-lg-8 col-xl-6">
          <v-img
              aspect-ratio="1"
              class="grey lighten-2"
              :lazy-src="user_setting.digital_signature_path"
              :src="user_setting.digital_signature_path"
              width="150"
              height="150"
          >
          </v-img>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Grade preferences:</label>
        <div class="col-lg-8 col-xl-6">
          {{user_setting.grade_preferences ? user_setting.grade_preferences : 'N/A'}}
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Instrument preferences:</label>
        <div class="col-lg-8 col-xl-6">
          {{user_setting.instrument_preferences ? user_setting.instrument_preferences : 'N/A'}}
        </div>
      </div>

      <div class="form-group row">
        <label class="col-xl-4 col-lg-4 col-form-label text-right">Qualification:</label>
        <div class="col-lg-8 col-xl-6">
          {{user_setting.qualifications ? user_setting.qualifications : 'N/A'}}
        </div>
      </div>

    </div>
    <v-overlay :value="supervisor_setting_overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <change-score ref="change-score" @update="getSupervisorDetail"></change-score>
  </div>
</template>

<script>
import UserSettingService from "@/services/user/UserSettingService";
import {email, required,requiredIf} from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";
import SupervisorService from "@/services/user/supervisor/SupervisorService";
import ChangeScore from "@/view/pages/view/user/general/score/ChangeScore";
const supervisor=new SupervisorService();
const score = new ScoreService();
const userSetting=new UserSettingService;

export default {

  name: "SupervisorSettings",
  components: {ChangeScore},
  validations:{
    user_setting:{
      wwcc_number: {
        required: requiredIf(function (nestedModel) {
          return this.setting.has_wwcc==true;
        })
      },
      wwcc_expiry_date: {
        required: requiredIf(function (nestedModel) {
          return this.setting.has_wwcc==true;
        })
      },
    }
  },

  data(){
    return{
      menu:false,
      menu1:false,
      menu2:false,
      edit:false,
      dialog:false,
      loading: false,
      is_wwcc_img: false,
      is_digital_signature: false,
      discount_types:[
        {text: 'Select Discount Type', value:''},
        {text: 'Fixed (in Dollars)', value: 'fixed'},
        {text: 'Percentage (between 1 and 100)', value: 'percentage'},
      ],
      user_score:{
        current_score_id:'',
        examining_scores:'',
      },
      instrument_preferences:[
        {text: 'Mixed', value: 'mixed'},
        {text: 'Low to high', value: 'low_to_high'},
        {text: 'High to low', value: 'high_to_low'},

      ],
      grade_preferences:[
        {text: 'Mixed', value: 'mixed'},
        {text: 'Syllabuses grouped but any order', value: 'grouped'},
        {text: 'High instruments to low', value: 'high_to_low'},
        {text: 'Low instruments to high', value: 'low_to_high'},
      ],
      setting:{
        notification_preference:'postal',
        phone_preference:'mobile',
        has_wwcc:false,
        user_id:'',
      },
      user_setting:{
        enable_school_pay:false,
        school_name:'',
        notification_preference:'',
        phone_preference:'',
        qualifications:'',
        digital_signature:null,
        wwcc_image:null,
        grade_preferences:'',
        instrument_preferences:'',
        has_wwcc:false,
        wwcc_number:'',
        wwcc_expiry_date:'',
      },

      digital_signature_img:null,
      wwcc_image:null,
      wwccShow:false,
      scores:[],
      examining_scores:[],
      errors:[],
      currentUser:{},
      supervisor_setting_overlay:false,
      subscription:{
        is_newsletter_subscribed:0,
        is_score_newsletter_subscribed:0,
        user_id:'',
      },
    }
  },
  methods:{
    changeNewsletterSubscription(value) {
      this.$confirm({
        message: value==1?`Just checking that you're wanting to sign up to our ENews. In doing so, we'll add you to our subscriber list and email your our latest news and updates.`:`Just checking that you're wanting to unsubscribe from our ENews. In doing so, we'll remove you from our list but you may miss out on hearing the latest news and updates.`,
        button: {
          no: "Cancel",
          yes: "Confirm",
        },
        callback: (confirm) => {
          if (confirm) {
            if(value==1){
              this.subscription.is_newsletter_subscribed=0;
            }else{
              this.subscription.is_newsletter_subscribed=1;
            }
            this.subscription.user_id=this.supervisorId;
            userSetting
                .changeNewsletter(this.subscription)
                .then(response => {
                  this.findByUserId();
                  this.$snotify.success("Notification updated");
                })
                .catch((err) => {
                  console.log(err)
                }).finally(() => {

            });
          }
        },
      });
    },
    changeScoreNewsletterSubscription(value) {
      this.$confirm({
        message: value==1?`Just checking that you're wanting to sign up to our ENews. In doing so, we'll add you to our subscriber list and email your our latest news and updates.`:`Just checking that you're wanting to unsubscribe from our ENews. In doing so, we'll remove you from our list but you may miss out on hearing the latest news and updates.`,
        button: {
          no: "Cancel",
          yes: "Confirm",
        },
        callback: (confirm) => {
          if (confirm) {
            if(value==1){
              this.subscription.is_score_newsletter_subscribed=0;
            }else{
              this.subscription.is_score_newsletter_subscribed=1;
            }
            this.subscription.user_id=this.supervisorId;
            userSetting
                .changeScoreNewsletter(this.subscription)
                .then(response => {
                  this.findByUserId();
                  this.$snotify.success("Score notification updated");
                })
                .catch((err) => {
                  console.log(err)
                }).finally(() => {

            });
          }
        },
      });
    },
    editSetting()
    {
      this.edit = true;
    },

    cancelEdit()
    {
      this.edit=false;
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    changeScore() {
      this.$refs['change-score'].openDialog(this.user_score, 'score');
    },
    changeExaminingScore() {
      this.$refs['change-score'].openDialog(this.user_score, 'examining_scores');
    },
    convertToFormData() {

      this.user_setting.has_wwcc=this.setting.has_wwcc;
      this.user_setting.notification_preference=this.setting.notification_preference;
      this.user_setting.phone_preference=this.setting.phone_preference;
      this.user_setting.user_id=this.supervisorId;

      let formData = new FormData();
      for (let key in this.user_setting) {
        if (key === "digital_signature" && this.user_setting[key] != null && this.user_setting[key] != undefined) {
          formData.append('digital_signature', this.user_setting[key]);
        }else if (key === "wwcc_image" && this.user_setting[key] != null && this.user_setting[key] != undefined) {
          formData.append('wwcc_image', this.user_setting[key]);
        }else {
          if(this.user_setting[key]){
            formData.append(key,this.user_setting[key]);
          }
        }
      }
      return formData;
    },

    viewDigitalSignature(digitalSignature){
      window.open(digitalSignature, "_blank");
    },

    toggleWwcc(){
      if(this.setting.has_wwcc==true){
        this.wwccShow=true;
        this.$v.$reset();
      }else{
        this.wwccShow=false;
        this.user_setting.wwcc_image = null;
        this.user_setting.wwcc_number = '';
        this.user_setting.wwcc_expiry_date = '';
      }
    },

    deleteDigitalSignatureImg() {
      if(this.user_setting && this.user_setting.id) {
        this.$confirm({
          message: `Are you sure you want to remove this signature? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              userSetting.deleteDigitalSignatureImg(this.user_setting.id).then(response => {
                if(response.data.status == "OK") {
                  this.$snotify.success("Digital signature deleted");
                  this.is_digital_signature = false;
                  this.digital_signature_img = null;
                  this.findByUserId();
                }
              }).catch(err => {});
            }
          }
        });
      }
    },

    deleteWWCCImg() {
      if(this.user_setting && this.user_setting.id) {
        this.$confirm({
          message: `Are you sure you want to remove this upload from child safety?`,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              userSetting.deleteWWCCImg(this.user_setting.id).then(response => {
                if(response.data.status == "OK") {
                  this.$snotify.success("Child safety upload removed");
                  this.is_wwcc_img = false;
                  this.wwcc_image = null;
                  this.findByUserId();
                }
              }).catch(err => {
                this.$snotify.error("Child safety remove error");
              });
            }
          }
        });
      }
    },

    previewWWCCImage(){
      this.is_wwcc_img = false;
      if(this.user_setting.wwcc_image != null){
        this.wwcc_image= URL.createObjectURL(this.user_setting.wwcc_image)
      }else{
        this.wwcc_image = null;
      }
    },
    previewDigitalSignature(){
      this.is_digital_signature = false;
      if(this.user_setting.digital_signature != null){
        this.digital_signature_img= URL.createObjectURL(this.user_setting.digital_signature)
      }else{
        this.digital_signature_img = null;
      }
    },
    createOrUpdateSetting(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {

        let formData = this.convertToFormData();
        this.supervisor_setting_overlay=true;
        userSetting
            .create(formData)
            .then(response => {
              this.$emit("update-supervisor");
              this.$snotify.success("Profile information updated");
              // this.updateScore();
              this.findByUserId();
              this.cancelEdit();
            })
            .catch(error => {
              this.errors = error.errors;
            }).finally(() => {
          this.supervisor_setting_overlay = false;
        });
      }
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    findByUserId(){
      userSetting
          .findByUserId(this.supervisorId)
          .then(response => {
            this.user_setting=response.data.userSetting;

            if(!this.user_setting){
              this.user_setting={};
            }else{
              if(this.user_setting && response.data.userSetting.digital_signature_path){
                  this.is_digital_signature = true;
                  this.digital_signature_img=response.data.userSetting.digital_signature_path;
                }
                if(this.user_setting && response.data.userSetting.wwcc_image_path){
                  this.is_wwcc_img = true;
                  this.wwcc_image=response.data.userSetting.wwcc_image_path;
                }
               if(this.user_setting && this.user_setting.notification_preference){
                  this.setting.notification_preference=this.user_setting.notification_preference;
                }
                if(this.user_setting && this.user_setting.phone_preference){
                 this.setting.phone_preference=this.user_setting.phone_preference;
               }

                if(this.user_setting && this.user_setting.has_wwcc){
                  this.setting.has_wwcc=this.user_setting.has_wwcc;
                }
                if(this.user_setting.is_newsletter_subscribed){
                this.subscription.is_newsletter_subscribed=this.user_setting.is_newsletter_subscribed;
              }
                if(this.user_setting.is_score_newsletter_subscribed){
                   this.subscription.is_score_newsletter_subscribed=this.user_setting.is_score_newsletter_subscribed;
                }
                this.toggleWwcc();
              }


          })
    },
    getSupervisorDetail() {
      supervisor
          .show(this.supervisorId)
          .then(response => {
            this.user_score=response.data.supervisor;
            if(this.user_score.examining_scores){
              this.examining_scores=this.user_score.examining_score_list;
            }
          })
    },
  },
  mounted() {
    this.getCurrentUser();
    this.getAllScore();
    this.findByUserId();
    this.getSupervisorDetail();
  },
  computed:{
    supervisorId() {
      return this.$route.params.supervisorId;
    },

  }
}
</script>

<style scoped>

</style>
