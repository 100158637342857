<template>
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="600"
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                <span>Child safety information update</span>
                <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeDialog">
                    <i class="fa fa-close"></i>
                </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row class="mt-2" v-if="loading">
                      <v-col>

                        <v-skeleton-loader
                            v-if="loading"
                            type="table-row-divider@1"
                        >
                        </v-skeleton-loader>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2" v-if="!loading">

                        <v-col cols="12" md="12">
                            <label class="">Child safety</label>
                            <div class="">
                                <v-switch
                                    v-model="child_safety.has_wwcc"
                                    @change="resetWWCC"
                                    hide-details
                                ></v-switch>
                            </div>
                        </v-col>

                        <v-col cols="12" md="12" v-if="child_safety.has_wwcc">
                            <v-text-field
                                outlined 
                                label="Card number"
                                dense  
                                :error="$v.child_safety.wwcc_number.$error"
                                v-model="child_safety.wwcc_number"
                            ></v-text-field>
                            <span class="text-danger" v-if="$v.child_safety.wwcc_number.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12" v-if="child_safety.has_wwcc">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="child_safety.wwcc_expiry_date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        label="Children safety expiry date"
                                        v-model="child_safety.wwcc_expiry_date"
                                        prepend-icon=""
                                        prepend-inner-icon="mdi-calendar"
                                        readonly outlined dense
                                        v-bind="attrs"
                                        v-on="on"
                                        :error="$v.child_safety.wwcc_expiry_date.$error"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="child_safety.wwcc_expiry_date"
                                    no-title
                                    scrollable
                                >
                                    <v-spacer></v-spacer>

                                    <v-btn
                                        text
                                        @click="menu = false"
                                    >
                                    Cancel
                                    </v-btn>
                                    <v-btn
                                        x-large
                                        dark
                                        @click="$refs.menu.save(child_safety.wwcc_expiry_date)"
                                    >
                                    Ok
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                            <span class="text-danger" v-if="$v.child_safety.wwcc_expiry_date.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12" v-if="child_safety.has_wwcc">
                            <div>
                                <v-file-input
                                    label="Upload"
                                    v-model="child_safety.wwcc_image"
                                    prepend-inner-icon="mdi-file"
                                    @change="previewWWCCImage"
                                    prepend-icon=""
                                    outlined
                                    dense
                                ></v-file-input>
                            </div>
                        </v-col>

                        <v-col cols="12" md="12" v-if="child_safety.has_wwcc && wwcc_image">
                            <div v-if="wwcc_image">
                                <v-img
                                    class="grey lighten-2"
                                    :lazy-src="wwcc_image"
                                    :src="wwcc_image"
                                    height="200px"
                                >
                                </v-img>
                              <v-btn
                                  @click="deleteWWCCImg"
                                  x-small
                                  right
                                  top
                                  fab
                                  absolute
                              >
                                <v-icon color="red">mdi-delete</v-icon>
                              </v-btn>
                              <a :href="child_safety.wwcc_image_path" v-if="child_safety.wwcc_image_path" target="_blank">View attachment</a>
                            </div>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    x-large
                    text
                    @click="closeDialog">
                    Cancel
                </v-btn>
                <v-btn
                    dark
                    x-large
                    @click="createOrUpdate()"
                >
                Save
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {required, requiredIf} from "vuelidate/lib/validators";
    import UserSettingService from "@/services/user/UserSettingService";

    const userSetting=new UserSettingService;
    
    export default {
        validations:{
          child_safety:{
                wwcc_number: {
                    required: requiredIf(function (nestedModel) {
                        return this.child_safety.has_wwcc==true;
                    })
                },
                wwcc_expiry_date: {
                    required: requiredIf(function (nestedModel) {
                        return this.child_safety.has_wwcc==true;
                    })
                },
            }
        },
        data(){
            return{
                dialog: false,
                menu: false,
                loading: true,
                is_wwcc_img: false,
                child_safety: {
                  has_wwcc: false,
                  wwcc_number: null,
                  wwcc_expiry_date: null,
                  wwcc_expiry_date_in_month: null,
                  wwcc_expiry_status: null,
                  wwcc_image: null,
                  wwcc_image_path: null,
                  user_id: null,
                  id: null
                },
                wwcc_image:null,
                wwccShow:0,
                examinerId: null
            }
        },
        methods:{
            openDialog(settings, examinerId){
                this.examinerId = examinerId;
                this.findByUserId();
                this.dialog = true;

            },
            closeDialog(){
                this.dialog = false;
              this.loading = true;
            },
            resetWWCC() {
                this.$v.$reset();
            },
            toggleWwcc(){
              if(this.child_safety.has_wwcc){
                this.wwccShow=1;
              }else{
                this.wwccShow=0;
              }
            },
            previewWWCCImage(){
                this.is_wwcc_img = false;
                if(this.child_safety.wwcc_image != null){
                    this.wwcc_image= URL.createObjectURL(this.child_safety.wwcc_image)
                }else{
                    this.wwcc_image = null;
                }
            },
          deleteWWCCImg() {
              this.$confirm({
                message: `Are you sure? `,
                button: {
                  no: "No",
                  yes: "Yes",
                },
                callback: (confirm) => {
                  if (confirm) {
                    userSetting.deleteWWCCImg(this.child_safety.id).then(response => {
                      if(response.data.status == "OK") {
                        this.$snotify.success("Child safety image deleted");
                        this.is_wwcc_img = false;
                        this.wwcc_image = null;
                        this.findByUserId();
                      }
                    }).catch(err => {});
                  }
                }
              });
          },
          findByUserId(){
              this.loading=true
            userSetting
                .findByUserId(this.examinerId)
                .then(response => {
                  if(!this.child_safety){
                    this.child_safety={};
                  }else{
                    this.child_safety.has_wwcc = response.data.userSetting.has_wwcc;
                    this.child_safety.wwcc_number = response.data.userSetting.wwcc_number;
                    this.child_safety.wwcc_expiry_date = response.data.userSetting.wwcc_expiry_date;
                    this.child_safety.wwcc_expiry_date_in_month = response.data.userSetting.wwcc_expiry_date_in_month;
                    this.child_safety.wwcc_expiry_status = response.data.userSetting.wwcc_expiry_status;
                    this.child_safety.wwcc_image = response.data.userSetting.wwcc_image;
                    this.child_safety.wwcc_image_path = response.data.userSetting.wwcc_image_path;
                    this.child_safety.user_id=this.examinerId;
                    this.child_safety.id=response.data.userSetting.id;
                    if(this.child_safety && response.data.userSetting.wwcc_image_path){
                      this.is_wwcc_img = true;
                      this.wwcc_image=response.data.userSetting.wwcc_image_path;
                    }
                    if(this.child_safety && response.data.userSetting.has_wwcc){
                      this.child_safety.has_wwcc=response.data.userSetting.has_wwcc;
                    }
                    this.toggleWwcc();
                    this.loading=false;
                  }
                })
          },
            convertToFormData() {
                this.child_safety.user_id=this.examinerId;
                let formData = new FormData();
                for (let key in this.child_safety) {

                    if (key === "wwcc_image" && this.child_safety[key] != null && this.child_safety[key] != undefined) {
                        formData.append('wwcc_image', this.child_safety[key]);
                    }
                    else if(key === "has_wwcc"){
                      if(this.child_safety.has_wwcc==false){
                        this.child_safety.wwcc_number = '';
                        this.child_safety.has_wwcc = 0;
                        this.child_safety.wwcc_expiry_date = '';
                        this.child_safety.wwcc_image = '';
                      }
                      formData.append(key,this.child_safety[key]);
                    }
                    else {
                        // if(this.child_safety[key]){
                            formData.append(key,this.child_safety[key]);
                        // }
                    }
                }
                console.log(formData)
                return formData;
            },
            createOrUpdate(){
              this.loading=true;
                this.$v.$touch()
                if (this.$v.$error) {
                    setTimeout(() => {
                    this.$v.$reset();
                    }, 3000);
                } else {
                    let formData = this.convertToFormData();
                    userSetting
                    .update(this.child_safety.id,formData)
                    .then(response => {
                        this.findByUserId();
                        this.$emit("refresh");
                        this.$snotify.success("Child safety updated");
                    })
                    .catch(error => {
                        this.errors = error.errors;
                    })
                    .finally(() => {
                        this.loading = false;
                        this.closeDialog();
                    });
                }
            },
        }
    }
</script>